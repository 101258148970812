<template>
	<svg class="Workflow">

		<g class="graph"/>

		<b-tooltip
			v-for="edge in edges"
			:key="edge.id"
			:target="() => edge.target"
			:title="edge.label"
			boundary="viewport"
			placement="right"/>

	</svg>
</template>

<script>
import * as d3 from 'd3';
import dagreD3 from 'dagre-d3';
import _ from 'lodash';

export default {
	props: {
		tasks: {
			type: Array
		}
	},
	data() {
		return {
			edges: []
		};
	},
	mounted() {
		this.refreshGraph();
	},
	methods: {
		refreshGraph() {
			this.graph = new dagreD3.graphlib.Graph();

			this.graph.setGraph({});

			if (!Array.isArray(this.tasks)) return;
			const tasks = this.tasks.filter(v => v);

			const nameToId = {};
			const idToId = {};

			tasks.forEach(task => {
				const id = task._id || '';
				nameToId[task.name] = id + task.name;
				if (id) {
					idToId[id] = nameToId[task.name];
				}
			});

			// Automatically label each of the nodes
			tasks.forEach(task => {
				const id = nameToId[task.name];


				let label = `${task.name}${(task.alias && task.alias !== 'default') ? ` (${task.alias})` : ''} `;
				if (task.alias && task.aliasType === 'edge') {
					label = `<img src='images/edge.png' style='margin-right: 3px;'>${label}`;
				}

				this.graph.setNode(
					id,
					{
						labelType: 'html',
						label
					}
				);

				const node = this.graph.node(id);

				if (task.status) {
					node.class = `task-status-${task.status}`;
				}

				// rounded corners
				node.rx = 3;
				node.ry = 3;

				if (task.depends) {
					task.depends.forEach(dep => {
						this.graph.setEdge(
							idToId[dep] || nameToId[dep],
							id,
							{ arrowhead: 'vee' }
						);
					});
				}
			});

			// Grab the SVG
			const svg = d3.select(this.$el);
			const inner = svg.select('g.graph');

			const render = dagreD3.render();
			render(inner, this.graph);

			this.edges = [];
			const self = this;
			inner.selectAll('g.edgePath')
				.attr('class', d => {
					const taskDidntRun = task => ['skipped', 'notstarted'].includes(task.status);
					const task = _.find(tasks, t => nameToId[t.name] === d.w);
					const taskFrom = _.find(tasks, t => nameToId[t.name] === d.v);
					const executed = !taskDidntRun(task) && !taskDidntRun(taskFrom);
					return `edgePath ${task.condition ? 'withCondition' : ''} ${!executed ? 'dashed' : ''}`;
				})
				.clone(true)
				.attr('class', 'invisibleEdgePath')
				.each(function addEdge(d) {
					const task = _.find(tasks, t => nameToId[t.name] === d.w);
					if (!task.condition) return;
					self.edges.push({
						target: this,
						label: self.parseCondition(task.condition),
						id: d.v + d.w + self.render
					});
				})
				.select('path')
				.attr('marker-end', '');

			// Center the graph
			svg.attr('width', this.graph.graph().width + 40);
			const translate = [(svg.attr('width') - this.graph.graph().width) / 2, 20];
			inner.attr('transform', `translate(${translate})`);
			svg.attr('height', this.graph.graph().height + 40);
		},
		parseCondition(condition) {
			if (!condition) return '';
			if (!_.isObject(condition)) return JSON.stringify(condition);
			if (_.isArray(condition)) return `[${condition.map(v => this.parseCondition(v)).join(', ')}]`;
			const op = Object.keys(condition)[0];
			if (op === 'var') return condition.var;
			const ops = {
				and: '\n&&',
				or: '\n||',
				in: '\nin'
			};
			return `(${_.map(condition[op], v => this.parseCondition(v)).join(` ${ops[op] || op} `)})`;
		}
	},
	watch: {
		tasks: {
			handler: 'refreshGraph',
			deep: true
		}
	}
};
</script>

<style lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~@workflow-solutions/ofs-vue-layout/src/styles/variables';


.Workflow {

	.invisibleEdgePath {
		path {
			stroke-width: 15px;
			stroke-opacity: 0;
			stroke: black;
		}
	}

	.edgePath {
		&.withCondition {
			path {
				stroke: $primary;
				fill: $primary;
			}
		}
		&.dashed {
			path {
				stroke-dasharray: 5, 5;
			}
		}

		path {
			stroke: $of-color-grey-2;
			fill: $of-color-grey-2;
			stroke-width: 1.5px;
		}
	}

	g.label {
		font-weight: normal;
	}

	.node {
		white-space: nowrap;
	}

	.node rect,
	.node circle,
	.node ellipse {
		stroke: #333;
		fill: #fff;
		stroke-width: 1.5px;
	}

	$statuses: (
		'notstarted': $of-color-status-complete,
		'queued': $of-color-status-pending,
		'started': $of-color-status-live,
		'retry': $of-color-status-pending,
		'ended': $of-color-status-ready,
		'failed': $of-color-status-error,
		'skipped': $of-color-grey-2,
	);

	@each $status, $value in $statuses {
		.task-status-#{$status} {
			rect {
				fill: $value;
				stroke: $value;
			}

			tspan {
				fill: color-yiq($value);
			}

			g.label {
				color: #fff;
			}
		}
	}

}
</style>
